import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

const sliders = document.querySelectorAll('.hero-slider .swiper');

if(sliders){
    sliders.forEach(item=>{
       const slider = new Swiper(item,{
           modules: [Navigation,Pagination, Autoplay],
           autoplay: {
               delay: 5000,
           },
           speed: 1500,
           // allowTouchMove: false,
           navigation: {
               nextEl: '.hero-slider__next',
               prevEl: '.hero-slider__prev',
           },
        })
    })
}